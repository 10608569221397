<template>
  <div class="caseSwiper">
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div class="swiper-slide swiper-slide4 swiper-no-swiping">
          <div class="left" style="display: flex; flex-direction: column; justify-content: space-between">
            <img class="imgBox2x1" :src="require(`@/assets/images/home/swiper17.png`)" alt="" />

            <div class="hen">
              <div
                class="textBox"
                style="
                  background: #101f30;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  align-items: flex-start;
                "
              >
                <div class="textInner">
                  <p style="color: #ffffff">云南乐多莓</p>
                  <p style="color: #ffffff">蓝莓种植基地</p>
                </div>
                <i class="iconfont icon-ic_arrow_long" style="transform: rotate(180deg); color: #ffffff"></i>
              </div>
              <img class="imgBox1x1" :src="require(`@/assets/images/home/swiper18.png`)" alt="" />
            </div>
          </div>
          <div class="right" style="display: flex; flex-direction: column; justify-content: space-between">
            <div class="hen">
              <img class="imgBox1x1" :src="require(`@/assets/images/home/swiper19.png`)" alt="" />
              <!-- <div
                class="textBox"
                style="
                  background: #f15642;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  align-items: flex-end;
                "
              >
                <div class="textInner" style="color: #ffffff; text-align: right">
                  <p>嘉心菜</p>
                  <p>数字薄膜大棚种植</p>
                </div>
                <i class="iconfont icon-ic_arrow_long" style="color: #ffffff"></i>
              </div> -->
              <img class="imgBox1x1" :src="require(`@/assets/images/home/swiper20.png`)" alt="" />
            </div>
            <img class="imgBox2x1" :src="require(`@/assets/images/home/swiper21.png`)" alt="" />
          </div>
        </div>
        <div class="swiper-slide swiper-slide1 swiper-no-swiping">
          <div class="left" style="display: flex; justify-content: space-between">
            <img class="imgBox1x2" :src="require(`@/assets/images/home/swiper1.png`)" alt="" />
            <div class="shu">
              <img class="imgBox1x1" :src="require(`@/assets/images/home/swiper2.png`)" alt="" />
              <div
                class="textBox"
                style="
                  background: #f8d86a;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  align-items: flex-end;
                "
              >
                <div class="textInner" style="color: #333333; text-align: right">
                  <p>鑫锋美丽乡村</p>
                  <p>智慧农业</p>
                </div>
                <i class="iconfont icon-ic_arrow_long"></i>
              </div>
            </div>
          </div>
          <div class="right" style="display: flex; flex-direction: column; justify-content: space-between">
            <div class="hen">
              <div
                class="textBox"
                style="
                  background: #2775b6;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  align-items: flex-start;
                "
              >
                <div class="textInner" style="color: #ffffff">
                  <p>范泾特色农产品</p>
                  <p>草莓智慧大棚</p>
                </div>
                <i class="iconfont icon-ic_arrow_long" style="color: #ffffff; transform: rotate(180deg)"></i>
              </div>
              <img class="imgBox1x1" :src="require(`@/assets/images/home/swiper4.png`)" alt="" />
            </div>
            <img class="imgBox2x1" :src="require(`@/assets/images/home/swiper3.png`)" alt="" />
          </div>
        </div>
        <div class="swiper-slide swiper-slide2 swiper-no-swiping">
          <div class="left" style="display: flex; flex-direction: column; justify-content: space-between">
            <img class="imgBox2x1" :src="require(`@/assets/images/home/swiper5.png`)" alt="" />

            <div class="hen">
              <div
                class="textBox"
                style="
                  background: #2c9678;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  align-items: flex-start;
                "
              >
                <div class="textInner" style="color: #ffffff">
                  <p>浙江宜葆</p>
                  <p>示范玻璃温室</p>
                </div>
                <i class="iconfont icon-ic_arrow_long" style="color: #ffffff; transform: rotate(180deg)"></i>
              </div>
              <img class="imgBox1x1" :src="require(`@/assets/images/home/swiper6.png`)" alt="" />
            </div>
          </div>
          <div class="right" style="display: flex; flex-direction: column; justify-content: space-between">
            <div class="hen">
              <img class="imgBox1x1" :src="require(`@/assets/images/home/swiper8.png`)" alt="" />
              <div
                class="textBox"
                style="
                  background: #61649f;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  align-items: flex-end;
                "
              >
                <div class="textInner" style="color: #ffffff">
                  <p>西北工业大学</p>
                  <p>长三角研究院</p>
                  <p>无土栽培应用</p>
                </div>
                <i class="iconfont icon-ic_arrow_long" style="color: #ffffff"></i>
              </div>
            </div>
            <img class="imgBox2x1" :src="require(`@/assets/images/home/swiper7.png`)" alt="" />
          </div>
        </div>
        <div class="swiper-slide swiper-slide3 swiper-no-swiping">
          <div class="left" style="display: flex; justify-content: space-between">
            <img class="imgBox1x2" :src="require(`@/assets/images/home/swiper9.png`)" alt="" />
            <div class="shu">
              <img class="imgBox1x1" :src="require(`@/assets/images/home/swiper10.png`)" alt="" />
              <div
                class="textBox"
                style="
                  background: #93d5dc;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  align-items: flex-end;
                "
              >
                <div class="textInner" style="color: #333333">
                  <p>浙江向阳农场</p>
                </div>
                <i class="iconfont icon-ic_arrow_long"></i>
              </div>
            </div>
          </div>
          <div class="right" style="display: flex; justify-content: space-between">
            <div class="shu">
              <div
                class="textBox"
                style="
                  background: #1ba784;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  align-items: flex-start;
                "
              >
                <div class="textInner" style="color: #ffffff">
                  <p>马家桥甜瓜</p>
                  <p>数字示范基地</p>
                </div>
                <i class="iconfont icon-ic_arrow_long" style="color: #ffffff; transform: rotate(180deg)"></i>
              </div>
              <img class="imgBox1x1" :src="require(`@/assets/images/home/swiper12.png`)" alt="" />
            </div>
            <img class="imgBox1x2" :src="require(`@/assets/images/home/swiper11.png`)" alt="" />
          </div>
        </div>
      </div>
      <!-- <div class="swiper-pagination"></div> -->
      <!-- <div class="swiper-scrollbar" id="swiper-scrollbar1"></div> -->
    </div>
    <div class="slideBtnArea">
      <div id="container">
        <div id="slider" class="slider">
          <div class="thumb">
            <img :src="require(`@/assets/logoThumb.png`)" alt="" />
          </div>
          <div class="sliderBlack"></div>
        </div>
      </div>
      <!-- <customProLine
        v-model="defaultValue"
        height="32"
        min="0"
        max="100"
        @input="xiupro"
        activeColor="#409eff"
        :use-slot="true"
        :disabled="false"
      >
        <div style="background: #ffffff; border-radius: 100%; width: 14px; height: 14px"></div>
      </customProLine> -->
    </div>
    <div class="scroll-m">
      <div class="scroll-slide">
        <div class="left" style="display: flex; flex-direction: column; justify-content: space-between">
          <img class="imgBox2x1" :src="require(`@/assets/images/home/swiper17.png`)" alt="" />

          <div class="hen">
            <div
              class="textBox"
              style="
                background: #101f30;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
              "
            >
              <div class="textInner">
                <p style="color: #ffffff">云南乐多莓</p>
                <p style="color: #ffffff">蓝莓种植基地</p>
              </div>
              <i class="iconfont icon-ic_arrow_long" style="transform: rotate(180deg); color: #ffffff"></i>
            </div>
            <img class="imgBox1x1" :src="require(`@/assets/images/home/swiper18.png`)" alt="" />
          </div>
        </div>
        <div class="right" style="display: flex; flex-direction: column; justify-content: space-between">
          <div class="hen">
            <img class="imgBox1x1" :src="require(`@/assets/images/home/swiper19.png`)" alt="" />
            <img class="imgBox1x1" :src="require(`@/assets/images/home/swiper20.png`)" alt="" />
            <!-- <div
              class="textBox"
              style="
                background: #f15642;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-end;
              "
            >
              <div class="textInner" style="color: #ffffff; text-align: right">
                <p>嘉心菜</p>
                <p>数字薄膜大棚种植</p>
              </div>
              <i class="iconfont icon-ic_arrow_long" style="color: #ffffff"></i>
            </div> -->
          </div>
          <img class="imgBox2x1" :src="require(`@/assets/images/home/swiper21.png`)" alt="" />
        </div>
      </div>
      <div class="scroll-slide">
        <div class="left" style="display: flex; justify-content: space-between">
          <img class="imgBox1x2" :src="require(`@/assets/images/home/swiper1.png`)" alt="" />
          <div class="shu">
            <img class="imgBox1x1" :src="require(`@/assets/images/home/swiper2.png`)" alt="" />
            <div
              class="textBox"
              style="
                background: #f8d86a;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-end;
              "
            >
              <div class="textInner" style="color: #333333; text-align: right">
                <p>鑫锋美丽乡村</p>
                <p>智慧农业</p>
              </div>
              <i class="iconfont icon-ic_arrow_long"></i>
            </div>
          </div>
        </div>
        <div class="right" style="display: flex; flex-direction: column; justify-content: space-between">
          <div class="hen">
            <div
              class="textBox"
              style="
                background: #2775b6;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
              "
            >
              <div class="textInner" style="color: #ffffff">
                <p>范泾特色农产品</p>
                <p>草莓智慧大棚</p>
              </div>
              <i class="iconfont icon-ic_arrow_long" style="color: #ffffff; transform: rotate(180deg)"></i>
            </div>
            <img class="imgBox1x1" :src="require(`@/assets/images/home/swiper4.png`)" alt="" />
          </div>
          <img class="imgBox2x1" :src="require(`@/assets/images/home/swiper3.png`)" alt="" />
        </div>
      </div>
      <div class="scroll-slide">
        <div class="left" style="display: flex; flex-direction: column; justify-content: space-between">
          <img class="imgBox2x1" :src="require(`@/assets/images/home/swiper5.png`)" alt="" />

          <div class="hen">
            <div
              class="textBox"
              style="
                background: #2c9678;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
              "
            >
              <div class="textInner" style="color: #ffffff">
                <p>浙江宜葆</p>
                <p>示范玻璃温室</p>
              </div>
              <i class="iconfont icon-ic_arrow_long" style="color: #ffffff; transform: rotate(180deg)"></i>
            </div>
            <img class="imgBox1x1" :src="require(`@/assets/images/home/swiper6.png`)" alt="" />
          </div>
        </div>
        <div class="right" style="display: flex; flex-direction: column; justify-content: space-between">
          <div class="hen">
            <img class="imgBox1x1" :src="require(`@/assets/images/home/swiper8.png`)" alt="" />
            <div
              class="textBox"
              style="
                background: #61649f;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-end;
              "
            >
              <div class="textInner" style="color: #ffffff">
                <p>西北工业大学</p>
                <p>长三角研究院</p>
                <p>无土栽培应用</p>
              </div>
              <i class="iconfont icon-ic_arrow_long" style="color: #ffffff"></i>
            </div>
          </div>
          <img class="imgBox2x1" :src="require(`@/assets/images/home/swiper7.png`)" alt="" />
        </div>
      </div>
      <div class="scroll-slide">
        <div class="left" style="display: flex; justify-content: space-between">
          <img class="imgBox1x2" :src="require(`@/assets/images/home/swiper9.png`)" alt="" />
          <div class="shu">
            <img class="imgBox1x1" :src="require(`@/assets/images/home/swiper10.png`)" alt="" />
            <div
              class="textBox"
              style="
                background: #93d5dc;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-end;
              "
            >
              <div class="textInner" style="color: #333333">
                <p>浙江向阳农场</p>
              </div>
              <i class="iconfont icon-ic_arrow_long"></i>
            </div>
          </div>
        </div>
        <div class="right" style="display: flex; justify-content: space-between">
          <div class="shu">
            <div
              class="textBox"
              style="
                background: #1ba784;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
              "
            >
              <div class="textInner" style="color: #ffffff">
                <p>马家桥甜瓜</p>
                <p>数字示范基地</p>
              </div>
              <i class="iconfont icon-ic_arrow_long" style="color: #ffffff; transform: rotate(180deg)"></i>
            </div>
            <img class="imgBox1x1" :src="require(`@/assets/images/home/swiper12.png`)" alt="" />
          </div>
          <img class="imgBox1x2" :src="require(`@/assets/images/home/swiper11.png`)" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'
import customProLine from '@/components/customProLine.vue'
export default {
  name: 'caseSwiper',
  components: { customProLine },
  props: {},
  data() {
    return {
      rate: 0,
      // swiper实例  实例上有各种方法
      swiperInstance: null,
      defaultValue: 0
    }
  },
  mounted() {
    let sliderBlack = slider.querySelector('.sliderBlack')
    let thumb = slider.querySelector('.thumb')
    var swiperInstance = new Swiper('.swiper-container', {
      spaceBetween: 20,
      watchSlidesProgress: true,
      autoplay: true, //可选选项，自动滑动
      on: {
        progress: function (swiper, progress) {
          // console.log(swiper, progress)
          // this.rate = progress
        },
        slideChangeTransitionStart: function () {
          // alert(this.activeIndex)

          this.rate = this.activeIndex
          // console.log('rate', this.rate)
        },

        slideChangeTransitionEnd: function () {
          //console.log('realIndex', this.realIndex) //切换结束时，告诉我现在是第几个slide
          // let leftValue = (this.realIndex + 1) * 155
          let leftValue = (this.realIndex + this.realIndex + 1) * 80
          thumb.style.left = leftValue + 'px'
          sliderBlack.style.width = leftValue + 'px'
          // setRate(leftValue)
        }
      }
      // pagination: {
      //   el: '.swiper-pagination',
      //   type: 'progressbar'
      // }
      // 如果需要滚动条
      // scrollbar: {
      //   el: '.swiper-scrollbar',
      //   hide: false, //不隐藏滚动条
      //   snapOnRelease: true, //如果设置为false，释放滚动条时slide不会自动贴合(我没看出有什么变化)
      //   dragSize: 30, //滚动条滑块的长度
      //   draggable: true //滚动条可拖拽可点击
      // }
    })

    let rate = container.querySelector('.rate')
    let shiftX
    const total = slider.offsetWidth - thumb.offsetWidth

    function onThumbDown(event) {
      event.preventDefault()

      shiftX = event.clientX - thumb.getBoundingClientRect().left

      thumb.setPointerCapture(event.pointerId)

      thumb.style.transform = 'scale(1)'

      thumb.onpointermove = onThumbMove

      thumb.onpointerup = (event) => {
        thumb.onpointermove = null
        thumb.onpointerup = null

        thumb.style.transform = 'scale(1.2)'
      }
    }

    function onThumbMove(event) {
      let newLeft = event.clientX - shiftX - slider.getBoundingClientRect().left

      if (newLeft < 0) {
        newLeft = 0
      }
      let rightEdge = slider.offsetWidth - thumb.offsetWidth
      if (newLeft > rightEdge) {
        newLeft = rightEdge
      }

      thumb.style.left = newLeft + 'px'
      sliderBlack.style.width = newLeft + 'px'
      setRate(newLeft)
    }

    function onThumbEnter(event) {
      thumb.style.transform = 'scale(1.2)'
      swiperInstance.autoplay.stop()
    }

    function onThumbLeave(event) {
      thumb.style.transform = 'scale(1)'
      swiperInstance.autoplay.start()
    }

    function setRate(left) {
      let leftValue = ((left / total) * 100).toFixed(0)
      // console.log('left', leftValue)

      let parama = 0
      let space = 0

      if (leftValue > 16 && leftValue < 49) {
        parama = (1 / 3) * 100
        space = 1
      } else if (leftValue >= 49 && leftValue < 84) {
        parama = (2 / 3) * 100
        space = 2
      } else if (leftValue >= 84) {
        parama = 100
        space = 3
      } else {
        parama = 0
      }
      // let rate = ((left / total) * 100).toFixed(0) / 100
      let rate = parama / 100

      swiperInstance.setTransition(1000)
      swiperInstance.setTranslate(-rate * 1200 * (4 - 1) - 20 * space)
    }
    thumb.onpointerdown = onThumbDown
    thumb.onpointerenter = onThumbEnter
    thumb.onpointerleave = onThumbLeave

    thumb.ondragstart = () => false
  },
  methods: {
    xiupro(value) {
      this.defaultValue = value
    }
  }
}
</script>
<style lang="scss" scoped>
.caseSwiper {
  width: 100%;
  height: 100%;
  // background: #ccc;
  // padding: 80px 0 0 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .swiper-container {
    width: 100%;
    // height: 700px;
    .swiper-scrollbar {
      width: 20%;
      background: #d8d8d8;
    }
    .swiper-slide {
      height: 600px;
      width: 1200px;
      display: flex;
      justify-content: space-between;
      .flexDirection {
        flex-direction: column;
      }
      .shu {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      .hen {
        display: flex;
        justify-content: space-between;
      }

      .left {
        height: 590px;
        width: 590px;
      }
      .right {
        height: 590px;
        width: 590px;
      }
      .imgBox1x2 {
        width: 285px;
        height: 590px;
      }
      .imgBox1x1 {
        width: 285px;
        height: 285px;
      }
      .imgBox2x1 {
        width: 590px;
        height: 285px;
      }
      .textBox {
        width: 285px;
        height: 285px;
        padding: 40px 30px 24px 24px;
        box-sizing: border-box;

        .textInner {
          font-size: 24px;
          font-weight: bold;
          line-height: 32px;
        }
      }
      .boxTypeRight {
      }
    }
  }
  @media only screen and (max-width: 1199px) {
    .swiper-container {
      display: none;
    }
  }
  .slideBtnArea {
    #container {
      margin: 6% auto;
      width: 640px;
      text-align: center;
    }
    .slider {
      border-radius: 2px;
      background: #e0e0e0;
      height: 2px;
      position: relative;
    }
    .sliderBlack {
      position: absolute;
      left: 0px;
      top: 0px;
      height: 2px;
      // width: 10px;
      background: #333;
    }

    .thumb {
      touch-action: none;
      width: 20px;
      height: 20px;
      border-radius: 20px;
      position: relative;
      left: 0px;
      top: -9px;
      // background: gray;
      cursor: pointer;
      transition: transform 0.3s;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .rate {
      margin-top: 20px;
    }
  }
  @media only screen and (max-width: 1199px) {
    .slideBtnArea {
      display: none;
    }
  }
  .scroll-m {
    box-sizing: border-box;
    padding: 0 20px 0 8px;
    width: 100vw;
    display: flex;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
    .scroll-slide {
      margin-left: 12px;
      // height: 256px;
      display: flex;
      .flexDirection {
        flex-direction: column;
      }
      .shu {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      .hen {
        display: flex;
        justify-content: space-between;
      }

      .left {
        height: 256px;
        width: 256px;
      }
      .right {
        margin-left: 12px;
        height: 256px;
        width: 256px;
      }
      .imgBox1x2 {
        width: 122px;
        height: 256px;
      }
      .imgBox1x1 {
        width: 122px;
        height: 122px;
      }
      .imgBox2x1 {
        width: 256px;
        height: 122px;
      }
      .textBox {
        width: 122px;
        height: 122px;
        padding: 12px 12px 12px 12px;
        box-sizing: border-box;

        .textInner {
          font-size: 12px;
          font-weight: bold;
          line-height: 16px;
        }
        .icon-ic_arrow_long {
          font-size: 8px;
        }
      }
      .boxTypeRight {
      }
    }
  }
  @media only screen and (min-width: 1200px) {
    .scroll-m {
      display: none;
    }
  }
}
</style>
