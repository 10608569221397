var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"u-slider",class:[_vm.disabled ? 'u-slider--disabled' : ''],style:({
    backgroundColor: _vm.inactiveColor
  }),attrs:{"id":"u-slider"},on:{"click":_vm.onClick}},[_c('div',{staticClass:"u-slider__gap",style:([
      _vm.barStyle,
      {
        height: _vm.height + 'px',
        backgroundColor: _vm.activeColor
      }
    ])},[_c('div',{staticClass:"u-slider__button-wrap",style:({ right: _vm.isWidth ? '10px' : '-7px' }),on:{"mousedown":_vm.onTouchStart,"mosemove":_vm.onTouchMove,"mouseleave":_vm.onTouchEnd,"mouseup":_vm.onTouchEnd}},[(_vm.$slots.default || _vm.$slots.$default)?_vm._t("default"):_c('div',{staticClass:"u-slider__button",style:([
          _vm.blockStyle,
          {
            height: _vm.blockWidth + 'px',
            width: _vm.blockWidth + 'px',
            backgroundColor: _vm.blockColor
          }
        ])})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }