<template>
  <div class="home">
    <navHeader :index="0"></navHeader>
    <!-- <div class="picWrapper">
      <i class="iconfont icon-ic_slogan1"></i>
      <img class="mouse" :src="require(`@/assets/images/home/mouse.png`)" alt="" />
      <img class="indexTopBg" :src="require(`@/assets/images/home/indexTopBg.png`)" alt="" />
    </div> -->

    <indexPicSwiper></indexPicSwiper>
    <!-- 解决方案 -->
    <div class="solutionWrapper">
      <div class="solutionWrapperInside">
        <div class="solutionBigTitle">数字农业解决方案服务商</div>
        <div class="solutionCardWrpper">
          <div
            @click="clickMore(item.type)"
            class="solutionCardItem"
            v-for="(item, index) in solutionList"
            :key="index"
          >
            <div class="solutionTitle">
              <!-- {{ item.title }} -->
              <i class="iconfont" :class="item.iconfont"></i>
            </div>
            <div class="solutionText">
              <!-- {{ item.text }} -->
              <div class="more">了解更多</div>
            </div>
            <img class="solutionImg" :src="item.pic" alt="" />
          </div>
        </div>

        <div class="solutionCardWrpper-m">
          <div class="solutionCardItem" v-for="(item, index) in solutionList" :key="index">
            <div class="solutionTitle">
              <!-- {{ item.title }} -->

              <i class="iconfont" :class="item.iconfont"></i>
            </div>
            <div class="solutionText">
              <div class="more" @click="clickMore(item.type)">了解更多</div>
            </div>
            <img class="solutionImg" :src="item.pic" alt="" />
          </div>
        </div>
      </div>
    </div>
    <!-- 案例分享 -->
    <div class="caseShareWrapper">
      <div class="caseShareInside">
        <div class="caseShareTitle">案例分享</div>
        <div class="caseShareSwiper">
          <caseSwiper></caseSwiper>
        </div>
      </div>
    </div>

    <!-- 视频推荐 -->
    <div class="videoWrapper">
      <div class="videoWrapperInside">
        <div class="videoLeft">
          <img class="videoPhonePill" :src="require(`@/assets/images/home/phonePill.png`)" alt="" />
          <div class="videoArea">
            <!-- <div id="videoContainer" class="videoContainer"></div> -->
            <iframe
              id="fram_box"
              frameborder="0"
              :src="currentVideo == '' ? videoArr[0] : currentVideo"
              :allowFullScreen="true"
            ></iframe>
            <!-- <video class="video" ref="videoPlayer" :controls="isPlaying" muted>
              <source :src="currentVideo" type="video/mp4" />
            </video> -->
            <!-- <img
              v-show="!isPlaying"
              class="playingImg"
              @click="playVideo"
              :src="require(`@/assets/images/commonUse/btnPlay.png`)"
              alt=""
            /> -->
          </div>
        </div>
        <div class="videoRight">
          <div class="videoRecommend">
            <div class="videoRecommendTitle">视频推荐</div>
            <div class="videoList">
              <div class="videoListItem" @click="videoClick(0)">
                <img class="videoPic" :src="require(`@/assets/images/home/videoPic1.png`)" alt="" />
                <img
                  class="playBtn"
                  :src="require(`@/assets/images/commonUse/btnPlay.png`)"
                  style="width: 56px; height: 56px"
                  alt=""
                />
              </div>
              <div class="videoListItem" @click="videoClick(1)">
                <img class="videoPic" :src="require(`@/assets/images/home/videoPic2.png`)" alt="" />
                <img
                  class="playBtn"
                  :src="require(`@/assets/images/commonUse/btnPlay.png`)"
                  style="width: 56px; height: 56px"
                  alt=""
                />
              </div>
              <div class="videoListItem" @click="videoClick(2)">
                <img class="videoPic" :src="require(`@/assets/images/home/videoPic3.png`)" alt="" />
                <img
                  class="playBtn"
                  :src="require(`@/assets/images/commonUse/btnPlay.png`)"
                  style="width: 56px; height: 56px"
                  alt=""
                />
              </div>
              <div class="videoListItem" @click="videoClick(3)">
                <img class="videoPic" :src="require(`@/assets/images/home/videoPic4.png`)" alt="" />
                <img
                  class="playBtn"
                  :src="require(`@/assets/images/commonUse/btnPlay.png`)"
                  style="width: 56px; height: 56px"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="codeList">
            <div class="codeListTitle">扫描下方二维码，查看更多数字农业相关视频</div>
            <div class="codePicList">
              <div class="codePicBox">
                <img class="codePic" :src="require(`@/assets/images/commonUse/qrCode1.png`)" alt="" />
                <div class="codePicSubTitle">微信视频号</div>
              </div>
              <div class="codePicBox">
                <img class="codePic" :src="require(`@/assets/images/commonUse/qrCode2.png`)" alt="" />
                <div class="codePicSubTitle">抖音</div>
              </div>
              <div class="codePicBox">
                <img class="codePic" :src="require(`@/assets/images/commonUse/qrCode3.png`)" alt="" />
                <div class="codePicSubTitle">快手</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="videoRecommend-m">
        <div class="videoRecommendTitle">视频推荐</div>
        <div class="videoList">
          <div class="videoListItem" @click="videoMobileClick(0)">
            <img class="videoPic" :src="require(`@/assets/images/home/videoPic1.png`)" alt="" />
            <img
              class="playBtn"
              :src="require(`@/assets/images/commonUse/btnPlay.png`)"
              style="width: 28px; height: 28px"
              alt=""
            />
          </div>
          <div class="videoListItem" @click="videoMobileClick(1)">
            <img class="videoPic" :src="require(`@/assets/images/home/videoPic2.png`)" alt="" />
            <img
              class="playBtn"
              :src="require(`@/assets/images/commonUse/btnPlay.png`)"
              style="width: 28px; height: 28px"
              alt=""
            />
          </div>
          <div class="videoListItem" @click="videoMobileClick(2)">
            <img class="videoPic" :src="require(`@/assets/images/home/videoPic3.png`)" alt="" />
            <img
              class="playBtn"
              :src="require(`@/assets/images/commonUse/btnPlay.png`)"
              style="width: 28px; height: 28px"
              alt=""
            />
          </div>
          <div class="videoListItem" @click="videoMobileClick(3)">
            <img class="videoPic" :src="require(`@/assets/images/home/videoPic4.png`)" alt="" />
            <img
              class="playBtn"
              :src="require(`@/assets/images/commonUse/btnPlay.png`)"
              style="width: 28px; height: 28px"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <!-- 合作伙伴 -->
    <div class="cooperatorWrapper">
      <div class="cooperatorWrapperInside">
        <div class="cooperatorBigTitle">合作伙伴</div>
        <div class="cooperatorCardWrpper">
          <div class="cooperatorCardItem" v-for="(item, index) in cooperatorList" :key="index">
            <img class="cooperatorImg" :src="item.pic" alt="" />
            <img class="cooperatorImgx2-m" :src="item.picx2" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="contactWrapper">
      <img class="indexbottomBg" :src="require(`@/assets/images/commonUse/bg.png`)" alt="" />
    </div>
    <div class="contactFooterWrapper-m">
      <contactFooter :type="1"></contactFooter>
    </div>

    <rightsFooter></rightsFooter>
    <videoModal :videoUrl="videoUrlMobile" v-if="isShowPopup" @close="closePopup"></videoModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import navHeader from '@/components/navHeader.vue'
import indexPicSwiper from '@/components/indexPicSwiper.vue'
import rightsFooter from '@/components/rightsFooter.vue'
import caseSwiper from '@/components/caseSwiper.vue'
import contactFooter from '@/components/contactFooter.vue'
import videoModal from '@/components/videoModal.vue'
import '@/utils/txplayer' // 腾讯视频js在本地的路径

export default {
  name: 'Home',
  components: { navHeader, rightsFooter, caseSwiper, contactFooter, videoModal, indexPicSwiper },
  data() {
    return {
      isPlaying: true,
      solutionList: [
        {
          title: '薄膜大棚',
          text: '我们的方案是一套非常简单易用和高效的数字薄膜大棚管理方案，它全程监测作物生长过程，并能灵活控制棚内设施，降低人工并促进增产提质，为...',
          pic: require(`@/assets/images/home/s1.png`),
          type: 0,
          iconfont: 'icon-shuzibaomodapeng'
        },
        {
          title: '果园大田',
          text: '我们的数字果园大田方案不仅仅是一套设备，更是一整套基于手机和电脑端的科学生产管理系统，能够 帮助农民降低成本，提高效率，实现增产提质。',
          pic: require(`@/assets/images/home/s2.png`),
          type: 1,
          iconfont: 'icon-shuziguoyuandatian'
        },
        {
          title: '玻璃温室',
          text: '我们的数字玻璃温室方案结合智能监测和控制中心软硬件，联动监测和智能设备， 增强温室运行效率和准确性，有效提升农场运营和管理水平，强化..',
          pic: require(`@/assets/images/home/s3.png`),
          type: 2,
          iconfont: 'icon-shuziboliwenshi'
        },
        {
          title: '水产',
          text: '尚阳数字水产养殖系统，集成不同形态的水质监测设备、智控设备以及视频监控， 通过物联网平台采集水质核心参数:水温、电导率、PH、溶解氧...',
          pic: require(`@/assets/images/home/s4.png`),
          type: 3,
          iconfont: 'icon-shuzishuichan'
        },
        {
          title: '无土栽培',
          text: '实时监测无土栽培中的空气和水质环境以及，精准掌握作物作物生长环境;同时根据微生态和作物生长模型，智能控制补光灯和循环水泵，让植物得到...',
          pic: require(`@/assets/images/home/s5.png`),
          type: 4,
          iconfont: 'icon-shuziwutuzaipei'
        },
        {
          title: '草莓种植',
          text: '通过数字管理系统，记录草莓生长周期各项参数，形成作物追溯报告和生产经验总结报告，帮助生产企业提高科学管理水平。',
          pic: require(`@/assets/images/home/s6.png`),
          type: 5,
          iconfont: 'icon-caomeishuzizhongzhiyingyong'
        }
      ],
      cooperatorList: [
        {
          pic: require(`@/assets/images/home/c1.png`),
          picx2: require(`@/assets/images/home/c1x2.png`)
        },
        {
          pic: require(`@/assets/images/home/c2.png`),
          picx2: require(`@/assets/images/home/c2x2.png`)
        },
        {
          pic: require(`@/assets/images/home/c3.png`),
          picx2: require(`@/assets/images/home/c3x2.png`)
        },
        {
          pic: require(`@/assets/images/home/c4.png`),
          picx2: require(`@/assets/images/home/c4x2.png`)
        },
        {
          pic: require(`@/assets/images/home/c5.png`),
          picx2: require(`@/assets/images/home/c5x2.png`)
        },
        {
          pic: require(`@/assets/images/home/c6.png`),
          picx2: require(`@/assets/images/home/c6x2.png`)
        },
        {
          pic: require(`@/assets/images/home/c7.png`),
          picx2: require(`@/assets/images/home/c7x2.png`)
        },
        {
          pic: require(`@/assets/images/home/c8.png`),
          picx2: require(`@/assets/images/home/c8x2.png`)
        }
      ],
      // currentVideo: 'http://clips.vorwaerts-gmbh.de/big_buck_bunny.mp4'
      currentVideo: '',
      videoArr: [
        'https://v.qq.com/txp/iframe/player.html?vid=a3510gpna3q',
        'https://v.qq.com/txp/iframe/player.html?vid=l3510di5zu1',
        'https://v.qq.com/txp/iframe/player.html?vid=l35118ftieb',
        'https://v.qq.com/txp/iframe/player.html?vid=u3511axxr1h'
      ],
      videoUrlMobile: '',
      isShowPopup: false
    }
  },
  computed: {},
  mounted() {
    // this.playerInit('u3511axxr1h')
  },
  methods: {
    playerInit(vid) {
      //调用且传腾讯视频的id即可
      var player = new Txplayer({
        containerId: 'videoContainer', //上面第一步html的id
        vid: vid,
        width: '100%',
        height: '100%',
        autoplay: false
      })
    },
    clickMore(type) {
      this.$router.push({
        path: '/solution',
        query: { type: type ? type : 0 }
      })
    },
    // playVideo() {
    //   let myVideo = this.$refs['videoPlayer']
    //   myVideo.play()
    //   this.isPlaying = false
    // },
    videoClick(e) {
      // console.log(e)

      this.currentVideo = this.videoArr[e]
    },
    videoMobileClick(e) {
      this.isShowPopup = true
      this.videoUrlMobile = this.videoArr[e]
    },
    openPopup() {
      this.isShowPopup = true
    },
    closePopup() {
      this.isShowPopup = false
    }
  }
}
</script>
<style lang="scss" scoped>
.home {
  // padding-bottom: 100px;
  .picWrapper {
    max-width: 100%;
    background: #f2f3ee;
    position: relative;
    .mouse {
      width: 24px;
      height: 88px;
      position: absolute;
      bottom: 20px;
      left: 50%;
      margin-left: -12px;
      animation: mymove 1s linear infinite alternate;
      -webkit-animation: mymove 1s linear infinite alternate; /* Safari 和 Chrome */
    }
    @media only screen and (min-width: 1200px) {
      .mouse {
      }
    }
    @media only screen and (max-width: 1199px) {
      .mouse {
        display: none !important;
      }
    }
    @-webkit-keyframes mymove {
      0% {
        bottom: 40px;
      }
      100% {
        bottom: 10px;
      }
    }
    @-moz-keyframes mymove {
      0% {
        bottom: 40px;
      }
      100% {
        bottom: 10px;
      }
    }
    @-o-keyframes mymove {
      0% {
        bottom: 40px;
      }
      100% {
        bottom: 10px;
      }
    }
    .iconfont {
      // text-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.5);
      opacity: 0.9;
      color: #fff;
      position: absolute;
      left: 32%;
      top: 42%;
      font-size: 170px;
      text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
    }
    @media only screen and (max-width: 1199px) {
      .iconfont {
        font-size: 70px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .indexTopBg {
      width: 100%;
      height: 100vh;
      object-fit: cover;
    }
  }
  .solutionWrapper {
    // height: 1164px;
    width: 100%;
    padding: 80px 0 80px 0;
    display: flex;
    align-content: center;
    justify-content: center;
    background: #f2f3ee;
    box-sizing: border-box;
    .solutionWrapperInside {
      height: 100%;
      width: 1200px;
      .solutionBigTitle {
        font-family: HarmonyOS Sans SC;
        font-size: 40px;
        font-weight: bold;
        line-height: 40px;
        text-align: center;
        letter-spacing: 0em;

        color: #333333;
        margin-bottom: 60px;
      }
      @media only screen and (max-width: 1199px) {
        .solutionBigTitle {
          font-size: 20px;
          line-height: 20px;
          margin-bottom: 30px;
        }
      }
      .solutionCardWrpper {
        display: grid;
        grid-gap: 60px;
        grid-template-columns: repeat(3, auto); /* 总共三列，占据剩余空间的比例相等 */
        grid-template-rows: repeat(2, auto); /* 总共两行，自适应高度 */
        .solutionCardItem {
          background: #ffffff;
          width: 360px;
          cursor: pointer;
          height: 384px;
          padding: 20px;
          box-sizing: border-box;
          position: relative;
          .solutionTitle {
            margin: 20px 0 20px 0;
            font-size: 24px;
            font-weight: normal;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0px;

            color: #333333;
          }
          .solutionText {
            // font-size: 14px;
            // font-weight: 300;
            // line-height: 32px;
            // text-align: justify;
            // letter-spacing: 0px;
            // color: #333333;
            height: 72px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .more {
              box-sizing: border-box;
              cursor: pointer;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 88px;
              height: 40px;
              font-size: 12px;
              border: 1px solid #333333;
            }
          }
          .solutionImg {
            position: absolute;
            bottom: 20px;
            left: 20px;
            width: 320px;
            height: 180px;
          }
        }
        .solutionCardItem:hover {
          box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.1);
          transition: all 0.5s ease-out;
        }
      }
      .solutionCardWrpper-m {
        box-sizing: border-box;
        padding: 0 20px 0 4px;
        display: flex;
        overflow-x: scroll;
        width: 100vw;
        -webkit-overflow-scrolling: touch;
        overflow-scrolling: touch;
        // overflow: hidden;
        .solutionCardItem {
          background: #ffffff;
          width: 312px;
          cursor: pointer;
          height: 246px;
          padding: 20px;
          box-sizing: border-box;
          position: relative;
          margin-left: 16px;
          .solutionTitle {
            // margin: 20px 0 20px 0;
            font-size: 18px;
            font-weight: normal;
            line-height: 18px;
            text-align: center;
            letter-spacing: 0px;

            color: #333333;
          }
          .solutionText {
            // font-size: 14px;
            // font-weight: 300;
            // line-height: 32px;
            // text-align: justify;
            // letter-spacing: 0px;
            // color: #333333;
            height: 72px;
            width: 272px;
            display: flex;
            justify-content: center;
            align-items: center;
            .more {
              box-sizing: border-box;

              display: flex;
              justify-content: center;
              align-items: center;
              width: 120px;
              height: 32px;
              font-size: 12px;
              border: 1px solid #333333;
            }
          }
          .solutionImg {
            position: absolute;
            bottom: 16px;
            left: 16px;
            width: 280px;
            height: 120px;
          }
        }
      }
      @media only screen and (min-width: 1200px) {
        .solutionCardWrpper-m {
          display: none;
        }
      }
      @media only screen and (max-width: 1199px) {
        .solutionCardWrpper {
          display: none;
        }
      }
    }
  }
  @media only screen and (max-width: 1199px) {
    .solutionWrapper {
      padding: 40px 0 40px 0;
    }
  }

  .caseShareWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 80px 0 80px 0;
    box-sizing: border-box;
    .caseShareInside {
      width: 1200px;
      display: flex;

      justify-content: center;
      flex-direction: column;
      .caseShareTitle {
        font-family: HarmonyOS Sans SC;
        font-size: 40px;
        font-weight: 900;
        line-height: 40px;
        text-align: center;
        letter-spacing: 0em;

        color: #333333;
        margin-bottom: 64px;
      }
      @media only screen and (max-width: 1199px) {
        .caseShareTitle {
          font-size: 20px;
          line-height: 20px;
          margin-bottom: 20px;
          font-weight: bold;
        }
      }
      .caseShareSwiper {
        // height: 800px;
      }
    }
    @media only screen and (max-width: 1199px) {
      .caseShareInside {
        width: 100vw;
      }
    }
  }
  @media only screen and (max-width: 1199px) {
    .caseShareWrapper {
      padding: 40px 0 40px 0;
    }
  }

  .videoWrapper {
    height: 780px;
    width: 100%;
    padding: 68px 0 0px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: radial-gradient(121% 121% at 50% 50%, #333333 0%, #222222 100%);
    box-sizing: border-box;
    .videoWrapperInside {
      height: 100%;
      width: 1200px;

      overflow: hidden;
      display: flex;
      .videoLeft {
        position: relative;
        background-image: url('~@/assets/images/home/phoneModal.png');
        background-repeat: no-repeat;
        width: 426px;
        height: 878px;
        margin-right: 130px;
        padding: 17px 0px 0 20px;
        box-sizing: border-box;
        .videoPhonePill {
          width: 127px;
          height: 36px;
          position: absolute;
          left: 149px;
          top: 28px;
          z-index: 99;
        }
        .videoArea {
          border-radius: 49px 49px 0 0;
          // background: #ccc;
          width: 390px;
          height: 699px;
          overflow: hidden;
          #fram_box {
            height: 100%;
            width: 100%;
            border-width: 0px;
          }
          video {
            width: 390px;
            height: 699px;
          }
          .playingImg {
            position: absolute;
            width: 56px;
            height: 56px;
            left: 190px;
            top: 358px;
            z-index: 99;
            cursor: pointer;
          }
        }
      }
      .videoRight {
        // background: rgb(43, 37, 37);
        width: 644px;
        height: 100%;
        .videoRecommend {
          height: 300px;
          width: 100%;
          border-top: 1px solid rgba(255, 255, 255, 0.2);
          border-bottom: 1px solid rgba(255, 255, 255, 0.2);
          box-sizing: border-box;
          .videoRecommendTitle {
            font-size: 24px;
            font-weight: bold;
            color: #ffffff;
            margin: 24px 0 16px 0;
          }
          .videoList {
            display: grid;
            grid-gap: 4px;
            grid-template-columns: repeat(4, auto); /* 总共三列，占据剩余空间的比例相等 */
            grid-template-rows: repeat(1, auto); /* 总共两行，自适应高度 */
            .videoListItem {
              cursor: pointer;
              position: relative;
              .videoPic {
                width: 158px;
                height: 210px;
              }
              .playBtn {
                z-index: 1;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate3d(-50%, -50%, 0);
              }
            }
          }
        }
        .codeList {
          margin-top: 90px;
          .codeListTitle {
            font-size: 24px;
            font-weight: bold;
            color: #ffffff;
            margin: 24px 0 24px 0;
          }
          .codePicList {
            display: flex;
            grid-gap: 64px;
            grid-template-columns: repeat(3, auto); /* 总共三列，占据剩余空间的比例相等 */
            grid-template-rows: repeat(1, auto); /* 总共两行，自适应高度 */

            .codePicBox {
              // display: flex;
              // flex-direction: column;
              .codePic {
                width: 172px;
                height: 173px;
                margin-bottom: 16px;
              }
              .codePicSubTitle {
                font-size: 14px;
                font-weight: bold;
                line-height: 14px;
                letter-spacing: 0em;

                color: #ffffff;
              }
            }
          }
        }
      }
    }
    @media only screen and (max-width: 1199px) {
      .videoWrapperInside {
        display: none;
      }
    }

    .videoRecommend-m {
      // height: 300px;
      width: 100%;
      // border-top: 1px solid rgba(255, 255, 255, 0.2);
      // border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      box-sizing: border-box;
      padding: 40px 20px 40px 20px;
      .videoRecommendTitle {
        font-size: 20px;
        font-weight: bold;
        color: #ffffff;
        margin: 0px 0 16px 0;
      }

      .videoList {
        display: grid;
        grid-gap: 4px;
        grid-template-columns: repeat(4, auto); /* 总共三列，占据剩余空间的比例相等 */
        grid-template-rows: repeat(1, auto); /* 总共两行，自适应高度 */
        justify-items: center;
        .videoListItem {
          cursor: pointer;
          position: relative;
          .videoPic {
            width: 82px;
            height: 109px;
          }
          .playBtn {
            z-index: 1;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate3d(-50%, -50%, 0);
          }
        }
      }
    }
    @media only screen and (min-width: 1200px) {
      .videoRecommend-m {
        display: none;
      }
    }
  }
  @media only screen and (max-width: 1199px) {
    .videoWrapper {
      height: 229px;
      padding: 0px 0 0px 0;
    }
  }
  .cooperatorWrapper {
    height: 864px;
    width: 100%;
    padding: 80px 0 0px 0;
    display: flex;
    align-content: center;
    justify-content: center;
    // background: #ccc;
    box-sizing: border-box;
    .cooperatorBigTitle {
      font-family: HarmonyOS Sans SC;
      font-size: 40px;
      font-weight: bold;
      line-height: 40px;
      text-align: center;
      letter-spacing: 0em;

      color: #333333;
      margin-bottom: 60px;
    }
    @media only screen and (max-width: 1199px) {
      .cooperatorBigTitle {
        font-size: 20px;
        line-height: 20px;
        margin-bottom: 0px;
      }
    }

    .cooperatorCardWrpper {
      width: 1200px;
      display: grid;
      grid-gap: 0px;
      grid-template-columns: repeat(4, auto); /* 总共三列，占据剩余空间的比例相等 */
      grid-template-rows: repeat(2, auto); /* 总共两行，自适应高度 */
      .cooperatorCardItem {
        // background: #ffffff;
        width: 300px;
        height: 300px;

        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      @media only screen and (max-width: 1199px) {
        .cooperatorCardItem {
          width: 100%;
          height: 167.5px;
          display: flex;
          justify-content: center;
          .cooperatorImg {
            display: none;
          }
          .cooperatorImgx2-m {
            width: 167.5px;
            height: 167.5px;
          }
        }
      }
      @media only screen and (min-width: 1200px) {
        .cooperatorCardItem {
          .cooperatorImgx2-m {
            display: none;
          }
        }
      }
    }
    @media only screen and (max-width: 1199px) {
      .cooperatorCardWrpper {
        width: 100vw;
        // padding: 20px 0 0px 20px;
        grid-template-columns: repeat(2, auto); /* 总共三列，占据剩余空间的比例相等 */
        grid-template-rows: repeat(4, auto); /* 总共两行，自适应高度 */
      }
    }
  }
  @media only screen and (max-width: 1199px) {
    .cooperatorWrapper {
      height: 100%;
      padding: 40px 0 0px 0;
    }
  }
  .contactWrapper {
    // background-image: url('~@/assets/images/commonUse/bg.png');
    // background-repeat: no-repeat;

    .indexbottomBg {
      width: 100%;
      height: auto;
    }
  }
  @media only screen and (max-width: 1199px) {
    .contactWrapper {
      display: none;
    }
  }
  @media only screen and (min-width: 1200px) {
    .contactFooterWrapper-m {
      display: none;
    }
  }
}
</style>
