<template>
  <div class="indexPicSwiper">
    <div class="swiper-container-indexPicSwiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide swiper-slide1">
          <div class="picWrapper">
            <i class="iconfont icon-ic_slogan1"></i>
            <img class="mouse" :src="require(`@/assets/images/home/mouse.png`)" alt="" />
            <img class="indexTopBg" :src="require(`@/assets/images/home/indexTopBg.png`)" alt="" />
          </div>
        </div>
        <div class="swiper-slide swiper-slide2">
          <div class="picWrapper">
            <img class="csbPicStyle" @click="goToCsb" :src="require(`@/assets/images/home/csb.png`)" alt="" />
            <div class="csbMask"></div>
            <img class="indexTopBg" :src="require(`@/assets/images/home/indexTopBg2.png`)" alt="" />
          </div>
        </div>
      </div>
      <!-- <div class="swiper-pagination"></div> -->
      <!-- <div class="swiper-scrollbar" id="swiper-scrollbar1"></div> -->
    </div>
    <!-- <div class="slideBtnArea">
      <div id="container">
        <div id="slider" class="slider">
          <div class="thumb">
            <img :src="require(`@/assets/logoThumb.png`)" alt="" />
          </div>
          <div class="sliderBlack"></div>
        </div>
      </div>
    </div> -->
    <div class="scroll-m">
      <div class="scroll-slide"></div>
      <div class="scroll-slide"></div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'

export default {
  name: 'indexPicSwiper',
  components: {},
  props: {},
  data() {
    return {
      rate: 0,
      // swiper实例  实例上有各种方法
      swiperInstance: null,
      defaultValue: 0
    }
  },
  mounted() {
    // let sliderBlack = slider.querySelector('.sliderBlack')
    // let thumb = slider.querySelector('.thumb')
    var swiperInstance = new Swiper('.swiper-container-indexPicSwiper', {
      spaceBetween: 0,
      watchSlidesProgress: true,
      autoplay: true //可选选项，自动滑动

      // pagination: {
      //   el: '.swiper-pagination',
      //   type: 'progressbar'
      // }
      // 如果需要滚动条
      // scrollbar: {
      //   el: '.swiper-scrollbar',
      //   hide: false, //不隐藏滚动条
      //   snapOnRelease: true, //如果设置为false，释放滚动条时slide不会自动贴合(我没看出有什么变化)
      //   dragSize: 30, //滚动条滑块的长度
      //   draggable: true //滚动条可拖拽可点击
      // }
    })
  },
  methods: {
    goToCsb() {
      this.$router.push({
        path: 'product?type=4'
      })
    },
    xiupro() {}
  }
}
</script>
<style lang="scss" scoped>
.indexPicSwiper {
  max-width: 100%;
  overflow: hidden;
}
.picWrapper {
  max-width: 100%;
  background: #f2f3ee;
  position: relative;
  .mouse {
    width: 24px;
    height: 88px;
    position: absolute;
    bottom: 20px;
    left: 50%;
    margin-left: -12px;
    animation: mymove 1s linear infinite alternate;
    -webkit-animation: mymove 1s linear infinite alternate; /* Safari 和 Chrome */
  }
  @media only screen and (min-width: 1200px) {
    .mouse {
    }
  }
  @media only screen and (max-width: 1199px) {
    .mouse {
      display: none !important;
    }
  }
  @-webkit-keyframes mymove {
    0% {
      bottom: 40px;
    }
    100% {
      bottom: 10px;
    }
  }
  @-moz-keyframes mymove {
    0% {
      bottom: 40px;
    }
    100% {
      bottom: 10px;
    }
  }
  @-o-keyframes mymove {
    0% {
      bottom: 40px;
    }
    100% {
      bottom: 10px;
    }
  }
  .iconfont {
    // text-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.5);
    opacity: 0.9;
    color: #fff;
    position: absolute;
    left: 32%;
    top: 42%;
    font-size: 170px;
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
  }
  @media only screen and (max-width: 1199px) {
    .iconfont {
      font-size: 70px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .indexTopBg {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }
  .csbMask {
    z-index: 3;
    position: absolute;
    width: 100%;
    height: 100vh;
    background: linear-gradient(270deg, #000000 0%, rgba(0, 0, 0, 0.1848) 80%, rgba(0, 0, 0, 0) 100%);
  }
  @media only screen and (max-width: 1199px) {
    .csbMask {
      background: none;
    }
  }
  .csbPicStyle {
    position: absolute;
    top: 15%;
    right: 18.75%;
    width: 512px;
    height: 664px;
    z-index: 4;
    cursor: pointer;
  }
  @media only screen and (max-width: 1199px) {
    .csbPicStyle {
      top: 50%;
      left: 50%;
      width: 256px;
      height: 332px;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
